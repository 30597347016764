var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{staticClass:"mt-2"},[(_vm.data && _vm.data.VolumeValues && _vm.data.VolumeValues.length)?_c('v-row',[_c('v-col',[_c('tfoms-bar',{attrs:{"title":"Выполнение плана","data":_vm.getValues(_vm.data.VolumeValues),"icon":"mdi-currency-rub","labels":_vm.labels,"sub-title":_vm.subTitlePeriod,"values":[
          {
            label: this.provideUnits.unit.text,
          } ]}})],1),_c('v-col',[_c('tfoms-lost-income',{attrs:{"plan":_vm.data.VolumeTotalPlanValue,"fact":_vm.data.VolumeTotalValue,"percent":_vm.data.VolumePercent}})],1)],1):_vm._e(),(_vm.data && _vm.data.Children)?_c('tfoms-condition-table',{staticClass:"mt-5",attrs:{"data":_vm.data.Children.map(function (e) { return ({
        plan: e.VolumePlanValue,
        fact: e.VolumeValue,
        percent: e.VolumeValuePercent,
        Name: e.Name,
        id: e.Id,
        Codes: e.Codes,
      }); }),"labels":_vm.labels,"subTitle":_vm.subTitlePeriod,"values":[
      {
        label: this.provideUnits.unit.text,
      } ]}}):_vm._e(),_c('v-row',{staticClass:"mt-5"},[_c('v-col',[(_vm.data && _vm.data.Divisions)?_c('tfoms-partition-table',{attrs:{"data":_vm.data.Divisions,"labels":_vm.labels,"sub-title":_vm.subTitlePeriod,"values":[
          {
            label: this.provideUnits.unit.text,
          } ]},model:{value:(_vm.selectEmployee),callback:function ($$v) {_vm.selectEmployee=$$v},expression:"selectEmployee"}}):_vm._e()],1),_c('v-col',[(_vm.data && _vm.data.Divisions)?_c('tfoms-employee-table',{attrs:{"selectPartiton":_vm.selectEmployee,"data":_vm.data.Divisions,"labels":_vm.labels,"items":_vm.data.Employees,"sub-title":_vm.subTitlePeriod,"values":[
          {
            label: this.provideUnits.unit.text,
          } ]}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }